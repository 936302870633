import React from "react";
import { Outlet, Navigate, useLocation } from "react-router-dom";
import { useStateContext } from "../contexts/ContextProvider";

const PrivateWrapper = () => {
  const { user } = useStateContext()
  const location = useLocation();

  return user ? <Outlet /> : <Navigate to='/welcome' state={{ from: location }} replace />;
};

export default PrivateWrapper;
