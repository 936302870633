import React, { useState, useEffect } from "react";
import styles from "./Home.module.css";
import {
  Silo,
  PaginationDots,
  UserPanel,
  NotificationsPanel,
  WeatherPanel
} from "../components";
import { AiOutlineWarning } from "react-icons/ai";
import { RiNotification2Line, RiUser3Line } from "react-icons/ri";
import silos_img from "../data/icons/silo.png";
import { useStateContext } from "../contexts/ContextProvider";
import { ToastContainer } from "react-toastify";
import { getCurrentWeather } from "../services/weatherService";
import "react-toastify/dist/ReactToastify.css";

const NavbarData = ({ handleWeatherClick, weather, setWeather }) => {
  const { configuration } = useStateContext();
  const [weatherLoading, setWeatherLoading] = useState(true);

  const updateWeather = async () => {
    if (configuration) {
      const res = await getCurrentWeather(configuration?.city);
      setWeather(res);
      setWeatherLoading(false);
    }
  };

  // Update weather
  useEffect(() => {
    updateWeather();
    const intervalId = setInterval(updateWeather, 2 * 60 * 60 * 1000); // 2h

    return () => {
      clearInterval(intervalId);
    };
  }, [configuration]);

  return (
    <>
      <div className={styles["navbar-data-container"]}>
        {!weatherLoading && (
          <div style={{ display: "flex", alignItems: "center" }} onClick={() => handleWeatherClick()}>
            <img
              src={weather?.current?.condition?.icon}
              alt='weather icon'
              className={styles["weather-icon"]}
            />
            <h1 style={{ color: "white" }}>{weather?.current?.temp_c}°C</h1>
          </div>
        )}
      </div>
    </>
  );
};

const Home = () => {
  const {
    silos,
    offline,
    newNotification,
    setNewNotification } =
    useStateContext();

  const [selectedSilo, setSelectedSilo] = useState(null);
  const [currentPage, setCurrentPage] = useState(0);
  const [elementsPerPage, setElementsPerPage] = useState(1);
  const [touchStartX, setTouchStartX] = useState(null);
  const [touchCurrentX, setTouchCurrentX] = useState(null);
  const [showUserPanel, setShowUserPanel] = useState(false);
  const [showNotificationsPanel, setShowNotificationsPanel] = useState(false);
  const [showWeatherPanel, setShowWeatherPanel] = useState(false);
  const [weather, setWeather] = useState(null);

  const onSiloSelected = (code) => {
    setSelectedSilo(code);
  };

  useEffect(() => {
    const handleResize = () => {
      const windowWidth = window.innerWidth;
      const siloWidth = windowWidth <= 380 ? 340 : 380;
      const elementsPerRow = Math.floor(windowWidth / siloWidth); // Silo width in px

      const maxPages = Math.ceil(silos.length / elementsPerRow);
      setCurrentPage(Math.min(currentPage, maxPages - 1));

      setElementsPerPage(elementsPerRow);
    };

    if (!selectedSilo) {
      window.addEventListener("resize", handleResize);
      handleResize();
      return () => {
        window.removeEventListener("resize", handleResize);
      };
    }
  }, [currentPage, silos.length, selectedSilo]);

  useEffect(() => {
    setShowNotificationsPanel(false);
    setShowUserPanel(false);
  }, [currentPage]);

  // Change current page on scroll
  const handleScroll = (e) => {
    if (!selectedSilo && e.deltaY !== undefined) {
      if (e.deltaY < 0 && currentPage < pages.length - 1) {
        setCurrentPage(currentPage + 1); // Scroll up
      } else if (e.deltaY > 0 && currentPage > 0) {
        setCurrentPage(currentPage - 1); // Scroll down
      }
    }
  };

  // Change current page on dot click
  const handlePageClick = (index) => {
    setCurrentPage(index);
  };

  const elements = silos?.map((silo) => (
    <Silo
      key={silo._id}
      id={silo._id}
      code={silo.code}
      content={silo.content}
      description={silo.description}
      supplier={silo.supplier}
      humidityRange={silo.humidityRange}
      maxTemperature={silo.maxTemperature}
      minStock={silo.minStock}
      humidity={10}
      vent={1}
      vibration={0}
      temperature={39}
      selectedSilo={selectedSilo}
      onSiloSelected={onSiloSelected}
    />
  ));

  const pages = elements?.reduce((acc, _, ii) => {
    if (ii % elementsPerPage === 0) {
      acc.push(elements.slice(ii, ii + elementsPerPage));
    }
    return acc;
  }, []);

  const handleWeatherClick = () => {
    setShowNotificationsPanel(false);
    setShowUserPanel(false);
    setShowWeatherPanel(!showWeatherPanel)
  }

  return (
    <>
      <div className={styles["page-container"]}>
        {/* Page background image */}
        <div className={styles["background-image"]}></div>
        {/* Notifications container */}
        {showNotificationsPanel && <NotificationsPanel setShowNotificationsPanel={setShowNotificationsPanel} />}
        {showUserPanel && <UserPanel setShowUserPanel={setShowUserPanel} />}
        {showWeatherPanel && <WeatherPanel setShowWeatherPanel={setShowWeatherPanel} weather={weather} />}
        {/* Navbar */}
        <div className={styles["navbar-container"]}>
          {/* Time and whether */}
          <NavbarData handleWeatherClick={handleWeatherClick} weather={weather} setWeather={setWeather} />
          {/* Notifications and user */}
          <div className={styles["navbar-buttons-container"]}>
            <div className={styles["notification-icon-container"]}>
              <RiNotification2Line
                className={styles["navbar-icon"]}
                onClick={() => {
                  setShowNotificationsPanel(!showNotificationsPanel);
                  setNewNotification(false);
                  setShowWeatherPanel(false);
                  setShowUserPanel(false);
                }}
              />
              {newNotification && (
                <div className={styles["notification-dot"]}></div>
              )}
            </div>
            <RiUser3Line
              className={styles["navbar-icon"]}
              onClick={() => {
                setShowNotificationsPanel(false);
                setShowWeatherPanel(false);
                setShowUserPanel(!showUserPanel);
              }}
            />
          </div>
        </div>
        {/* Offline banner */}
        {offline && (
          <div className={styles["offline-container"]}>
            <AiOutlineWarning className={styles["offline-container-icon"]} />
            <h2>Offline</h2>
          </div>
        )}

        {/* Silo list */}
        {silos.length > 0 ? (
          <div
            className={styles["silos-list-container"]}
            onWheel={handleScroll}
            onTouchStart={(e) => setTouchStartX(e.touches[0].clientX)}
            onTouchMove={(e) => setTouchCurrentX(e.touches[0].clientX)}
            onTouchEnd={() => {
              if (touchStartX && touchCurrentX && !selectedSilo) {
                const deltaX = touchCurrentX - touchStartX;

                if (Math.abs(deltaX) > 50) {
                  if (deltaX > 0 && currentPage > 0) {
                    setCurrentPage(currentPage - 1); // Scroll right
                  } else if (deltaX < 0 && currentPage < pages.length - 1) {
                    setCurrentPage(currentPage + 1); // Scroll left
                  }
                }
              }

              setTouchStartX(null);
              setTouchCurrentX(null);
            }}
          >
            <div className={styles["silos-page"]}>{pages[currentPage]}</div>
          </div>
        ) : (
          <div className={styles["no-data-container"]}>
            <h2>Nessun silo ancora configurato </h2>
            <img src={silos_img} alt='silos' />
            <p>Stai già utilizzando Demetra ma visualizzi questo messaggio?</p>
            <a href='mailto:supporto@missiona.com'>
              Contatta il supporto MissionA
            </a>
          </div>
        )}

        {/* Pagination dots */}
        {!selectedSilo && (
          <PaginationDots
            pageCount={pages.length}
            currentPage={currentPage}
            onPageClick={handlePageClick}
          />
        )}

        {/* Footer */}
        <div className={styles["footer-container"]}>
          <p>Powered by MissionA srl</p>
        </div>
      </div>

      {/* Toast notifications container */}
      <ToastContainer
        theme='dark'
        position='top-center'
        className={styles["toast-container"]}
      />
    </>
  );
};

export default Home;
