import React, { useEffect } from "react";
import { RiAlarmWarningLine, RiInformationLine } from "react-icons/ri";
import { AiOutlineWarning, AiOutlineClose } from "react-icons/ai";
import styles from "./NotificationsPanel.module.css";
import { useStateContext } from "../contexts/ContextProvider";

const NotificationsPanel = ({ setShowNotificationsPanel }) => {
  const { notifications, setNotifications, setNewNotification, formatTimestamp } =
    useStateContext();

  useEffect(() => {
    // Get stored notifications
    const storedNotificationsJSON = localStorage.getItem("notifications");
    let storedNotifications = [];

    // Check if there are any stored
    if (storedNotificationsJSON) {
      storedNotifications = JSON.parse(storedNotificationsJSON);
    }

    // Check if the number of notifications exceeds the limit
    if (storedNotifications.length > 8) {
      // Remove the oldest notification (the first one in the array)
      storedNotifications.shift();
    }

    // Set notifications var state
    setNotifications(storedNotifications);
  }, []);

  // Map notification level
  const getNotificationIcon = (level) => {
    switch (level) {
      case "alarm":
        return (
          <RiAlarmWarningLine
            className={styles["notification-icon"]}
            style={{ color: "var(--alert-icon)" }}
          />
        );
      case "warning":
        return (
          <AiOutlineWarning
            className={styles["notification-icon"]}
            style={{ color: "var(--cat-yellow)" }}
          />
        );
      case "info":
        return <RiInformationLine className={styles["notification-icon"]} />;
      default:
        // Warning is the default level
        return (
          <AiOutlineWarning
            className={styles["notification-icon"]}
            style={{ color: "var(--cat-yellow)" }}
          />
        );
    }
  };

  return (
    <div className={styles["notifications-panel-container"]}>
      <AiOutlineClose
        className={styles["close-button"]}
        onClick={() => {
          setShowNotificationsPanel(false);
          setNewNotification(false);
        }}
      />
      <h2 className={styles["notifications-title"]}>Notifiche</h2>
      <div className={styles["notifications-list"]}>
        {!notifications || notifications.length === 0 ? (
          <div className={styles["no-notifications"]}>
            <p>Nessuna notifica</p>
          </div>
        ) : (
          // Map the reverse notifications array (last notification on top)
          notifications
            .map((notification, index) => (
              <div key={index} className={styles["notification"]}>
                {getNotificationIcon(notification.level)}
                <div className={styles["notification-data"]}>
                  <p className={styles["notification-title"]}>
                    {notification.title}
                  </p>
                  <p className={styles["notification-body"]}>
                    {notification.body}
                  </p>
                  <p className={styles["notification-timestamp"]}>
                  {formatTimestamp(notification.timestamp, '', 'dd-MM-yy HH:mm:ss')}
                  </p>
                </div>
              </div>
            ))
            .reverse()
        )}
      </div>
    </div>
  );
};

export default NotificationsPanel;
