import React from "react";
import styles from "./PaginationDots.module.css";
import { RxDotFilled } from "react-icons/rx";

const PaginationDots = ({ pageCount, currentPage, onPageClick }) => {
  const dots = [];
  for (let i = 0; i < pageCount; i++) {
    dots.push(
      <RxDotFilled
        key={i}
        onClick={() => onPageClick(i)}
        className={
          i === currentPage ? styles["dot-active"] : styles["dot-inactive"]
        }
      />
    );
  }

  return <div className={styles["dots-container"]}>{dots}</div>;
};

export default PaginationDots;
