import React, { useState, useEffect } from "react";
import styles from "./Silo.module.css";
import { RxUpdate } from "react-icons/rx";
import { GiDroplets } from "react-icons/gi";
import { TbTemperature } from "react-icons/tb";
import { FaArrowRight } from "react-icons/fa";
import { FaRegCalendarXmark } from "react-icons/fa6";
import { MdModeEditOutline } from "react-icons/md";
import { BsDatabaseX } from "react-icons/bs";
import { PiFan } from "react-icons/pi";
import { RiTruckLine } from "react-icons/ri";
import { SwishSpinner } from "react-spinners-kit";
import { GiZigzagHieroglyph } from "react-icons/gi";
import { useStateContext } from "../contexts/ContextProvider";
import MultilineChart from "./MultilineChart";
import { getSilo, getSiloData, getSiloHistory, getSiloStockHistory, getUpdates } from "../services/apiService";
import EditForm from "./EditForm";
import SiloSvg from "./SiloSvg";

const Silo = ({ id, code, selectedSilo, onSiloSelected }) => {
  const { createAxiosInstance, offline, setOffline, formatTimestamp } =
    useStateContext();
  const [isExpanded, setIsExpanded] = useState(false);
  const [anagData, setAnagData] = useState(null);
  const [data, setData] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [isChartLoading, setIsChartLoading] = useState(true);
  const [isUpdatesQueueLoading, setIsUpdatesQueueLoading] = useState(true);
  const [chartData, setChartData] = useState(null);
  const [stockChartData, setStockChartData] = useState(null);
  const [editMode, setEditMode] = useState(false);
  const [updatesQueue, setUpdatesQueue] = useState(null);

  // Fetch silo hot data
  const fetchData = async () => {
    try {
      const api = await createAxiosInstance();
      const resSiloData = await getSiloData(api, id);
      setData(resSiloData[0]);

      setAnagData(await getSilo(api, id));
      setChartData(await getSiloHistory(api, id));
      setStockChartData(await getSiloStockHistory(api, id));
      setOffline(false);
    } catch (error) {
      console.log(error);
      setOffline(true);
      // Clear charts
      setChartData([]);
      setStockChartData([]);
    } finally {
      setIsLoading(false);
    }
  };

  // Fetch silo data on component rendering and evry (10) seconds
  useEffect(() => {
    fetchData();

    const intervalId = setInterval(() => {
      fetchData();
    }, 10000);

    return () => {
      // Remove interval when componente dismount
      clearInterval(intervalId);
    };
  }, []);

  // Fetch if change editmode
  useEffect(() => {
    // Fetch silo anag data
    const fetchAnagData = async () => {
      try {
        const api = await createAxiosInstance();
        const anag = await getSilo(api, id);

        setAnagData(anag);
      } catch (error) {
        console.log(error);
      }
    };

    // Fetch updates
    const fetchUpdates = async () => {
      try {
        const api = await createAxiosInstance();
        const updates = await getUpdates(api, id);

        setUpdatesQueue(updates);
        setIsUpdatesQueueLoading(false);
      } catch (error) {
        console.log(error);
        setIsUpdatesQueueLoading(false);
      }
    };

    if (!editMode && !offline) {
      fetchAnagData();
    } else if (editMode) {
      setIsUpdatesQueueLoading(true)
      fetchUpdates()
    }
  }, [editMode]);

  // Fetch silo history data
  useEffect(() => {
    const fetchData = async () => {
      try {
        const api = await createAxiosInstance();
        setChartData(await getSiloHistory(api, id));
        setStockChartData(await getSiloStockHistory(api, id));
        setIsChartLoading(false);
      } catch (error) {
        setOffline(true);
        setChartData([]);
      }
    };

    if (isExpanded) {
      setIsChartLoading(true);
      fetchData();
    }
  }, [isExpanded]);

  // Check render conditions
  if (selectedSilo !== null && selectedSilo !== code) {
    return null;
  }

  return (
    <div
      className={`${styles["silo-container"]} ${isExpanded ? styles["expanded"] : ""
        }`}
    >
      <div className={styles["silo-navbar"]}>
        <div>
          <h2>{anagData?.name.toUpperCase()}</h2>
          <h4>#{code}</h4>
        </div>
        <div className={styles["silos-buttons-container"]}>
          {isExpanded && (
            <MdModeEditOutline
              className={styles["expand-icon"]}
              onClick={() => setEditMode(!editMode)}
            />
          )}
          {data !== null && data !== undefined && (
            <FaArrowRight
              className={`${styles["expand-icon"]} ${isExpanded ? styles["rotated"] : ""
                }`}
              onClick={() => {
                if (isExpanded === false) {
                  onSiloSelected(code);
                  setIsExpanded(true);
                } else {
                  onSiloSelected(null);
                  setEditMode(false);
                  setIsExpanded(false);
                }
              }}
            />
          )}
        </div>
      </div>
      <div className={styles["section-splitter"]}>
        <div
          className={
            isLoading
              ? `${styles["section-preview-loading"]}`
              : `${styles["section-preview"]}`
          }
        >
          {isLoading ? (
            <SwishSpinner size={50} />
          ) : (
            <>
              {" "}
              <div className={styles["preview-data-splitter"]}>
                {/* Hot data */}
                <div className={styles["data-container"]}>
                  <div className={styles["data-element"]}>
                    <GiDroplets className={styles["data-icon"]} />
                    <p>{data?.internalHumidity ?? "Nan"}%</p>
                  </div>
                  <div className={styles["data-element"]}>
                    <TbTemperature className={styles["data-icon"]} />
                    <p>{data?.internalTemperature ?? "Nan"}°C</p>
                  </div>
                  <div className={styles["data-element"]}>
                    <FaRegCalendarXmark className={styles["data-icon"]} />
                    <p>
                      {data && data.outOfStock
                        ? formatTimestamp(data.outOfStock, "", "dd/MM/yy")
                        : "NaN"}
                    </p>
                    <p className={styles["reliability-level"]}>
                      {data?.predictionError && (data?.predictionError > 0.2
                        ? "incerto"
                        : data?.predictionError > 0.05
                          ? "affidabile"
                          : "preciso")}
                    </p>
                  </div>
                </div>
                <div className={styles["animated-icons-container"]}>
                  {data?.bypass ? (
                    <>
                      <PiFan
                        className={`${styles["animated-icon"]} ${styles["bypass"]}`}
                      />
                      <GiZigzagHieroglyph
                        className={`${styles["animated-icon"]} ${styles["bypass"]}`}
                      />
                    </>
                  ) : (
                    <>
                      <PiFan
                        className={`${styles["animated-icon"]} ${data?.fanActive === 1 && styles["vent-running"]
                          }`}
                      />
                      <GiZigzagHieroglyph
                        className={`${styles["animated-icon"]} ${data?.engineActive === 1 && styles["vibration-running"]
                          }`}
                      />
                    </>
                  )}
                </div>
              </div>
              <div className={styles["svg-container"]}>
                {/* Sleep container */}
                {data?.sleep === 1 && (
                  <div className={styles["sleep-container"]}>
                    <div className={`${styles["zzz"]} ${styles["zzz-zzz"]}`}>
                      Z
                    </div>
                    <div className={`${styles["zzz"]} ${styles["zzz-zz"]}`}>
                      Z
                    </div>
                    <div className={`${styles["zzz"]} ${styles["zzz-z"]}`}>
                      Z
                    </div>
                  </div>
                )}
                <SiloSvg
                  code={anagData?.code}
                  minStock={anagData?.stock?.min}
                  data={data}
                  max_values={anagData?.distances?.max}
                  offset_values={anagData?.distances?.offsets}
                  onSiloSelected={onSiloSelected}
                  setIsExpanded={setIsExpanded}
                />
              </div>
            </>
          )}
        </div>
        {/* Expanded section */}
        {isExpanded && (
          <div className={styles["section-full-data"]}>
            {editMode ? (
              isUpdatesQueueLoading ? (
                <div className={styles["loading-update-container"]}>
                  <SwishSpinner size={50} />
                </div>
              ) : (
                updatesQueue === null || updatesQueue.length === 0 ? (
                  <EditForm
                    id={id}
                    code={code}
                    name={anagData?.name}
                    content={anagData?.content}
                    description={anagData?.description}
                    supplier={anagData?.supplier}
                    sleepMode={anagData?.sleepMode}
                    fan={anagData?.fan}
                    vibration={anagData?.vibration}
                    stock={anagData?.stock}
                    log={anagData?.log}
                    setEditMode={setEditMode}
                  />
                ) : (
                  <div className={styles["update-message-container"]}>
                    <h4 className={styles["update-message"]}>Silos in aggiornamento</h4>
                    <RxUpdate className={styles["update-icon"]} />
                    <p>Un aggiornamento è già in corso, riprova più tardi</p>
                  </div>
                )
              )
            ) : (
              <div className={styles["section-full-data-info-container"]}>
                <h3>
                  Contenuto: <span>{anagData?.content}</span>
                </h3>
                <p>{anagData?.description}</p>
                <p>
                  <RiTruckLine />
                  <span>{anagData?.supplier}</span>
                </p>
                <br />
              </div>
            )}

            {/* Grafici */}
            {!editMode && (
              <div className={styles["section-full-data-graph-container"]}>
                <div className={styles["chart-container"]}>
                  <h3>Conservazione [12h]:</h3>
                  {chartData?.length > 0 ? (
                    isChartLoading ? (
                      <div className={styles["chart-loading"]}>
                        <SwishSpinner size={50} />
                      </div>
                    ) : (
                      <MultilineChart data={chartData} />
                    )
                  ) : (
                    <div className={styles["chart-empty"]}>
                      <p>Dati insufficienti</p>
                      <BsDatabaseX className={styles["chart-empty-icon"]} />
                    </div>
                  )}
                </div>
                <div className={styles["chart-container"]}>
                  <h3>Svuotamento [30 g]:</h3>
                  {stockChartData?.length > 0 ? (
                    isChartLoading ? (
                      <div className={styles["chart-loading"]}>
                        <SwishSpinner size={50} />
                      </div>
                    ) : (
                      <MultilineChart data={stockChartData} />
                    )
                  ) : (
                    <div className={styles["chart-empty"]}>
                      <p>Dati insufficienti</p>
                      <BsDatabaseX className={styles["chart-empty-icon"]} />
                    </div>
                  )}
                </div>
              </div>
            )}
          </div>
        )}
      </div>
    </div>
  );
};

export default Silo;
