import React, { useState, useEffect } from "react";
import { useStateContext } from "../contexts/ContextProvider";
import { AiOutlineClose } from "react-icons/ai";
import styles from "./UserPanel.module.css";

const UserPanel = ({setShowUserPanel}) => {
    const { user, logout } = useStateContext();

    return (
        <div className={styles["user-panel"]}>
            <AiOutlineClose
                className={styles["close-button"]}
                onClick={() => {
                    setShowUserPanel(false);
                }}
            />
            <h2 className={styles["user-title"]}>User</h2>
            <div className={styles["user"]}>
                <div className={styles["avatar-circle"]}>
                    {user?.photoURL ? (
                        <img src={user.photoURL} alt="User Avatar" />
                    ) : (
                        <div className={styles["initials"]}>{user?.email.slice(0, 2).toUpperCase()}</div>
                    )}
                </div>
                <h3>{user?.email}</h3>
            </div>
            <button onClick={() => logout()}>Logout</button>
        </div>
    );
};

export default UserPanel;
