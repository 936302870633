// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import { getAuth } from "firebase/auth";
import { initializeAppCheck, ReCaptchaV3Provider } from "firebase/app-check";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: process.env.REACT_APP_API_KEY,
  authDomain: process.env.REACT_APP_AUTH_DOMAIN,
  projectId: process.env.REACT_APP_PROJECT_ID,
  storageBucket: process.env.REACT_APP_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_MESSAGING_SENDER_ID,
  appId: process.env.REACT_APP_APP_ID,
  measurementId: process.env.REACT_APP_MEASUREMENT_ID,
};

// App check in development
if (!process.env.NODE_ENV || process.env.NODE_ENV === "development") {
  window.FIREBASE_APPCHECK_DEBUG_TOKEN =
    process.env.REACT_APP_FIREBASE_APPCHECK_DEBUG_TOKEN;
}

// Initialize Firebase
export const app = initializeApp(firebaseConfig);
// Analytics
export const analytics = getAnalytics(app);
// User auth
export const auth = getAuth(app);
export const appCheck = initializeAppCheck(app, {
  provider: new ReCaptchaV3Provider(
    process.env.REACT_APP_REACAPTCHA_V3_PROVIDER_PUBLIC_TOKEN
  ),
  isTokenAutoRefreshEnabled: true,
});
