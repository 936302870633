import { React } from "react";
import { BrowserRouter, Routes, Route, Navigate } from "react-router-dom";
import {
  Welcome,
  Home,
} from "./pages";

import './App.css';
import { ContextProvider } from "./contexts/ContextProvider";
import PrivateWrapper from "./layout/PrivateWrapper";

function App() {
  return (
    <ContextProvider>
      <BrowserRouter>
        <Routes>
        <Route path='*' element={<Navigate to='/home'/>} />
          <Route path='/welcome' element={<Welcome />} />
          <Route element={<PrivateWrapper />}>
            <Route path="/home" element={<Home />} />
          </Route>
        </Routes>
      </BrowserRouter>
    </ContextProvider>
  );
}

export default App;
