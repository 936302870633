// Import Axios
import axios from "axios";

// Base URL for the weather API (replace with your URL)
const WEATHER_BASE_URL = process.env.REACT_APP_API_WEATHER_BASE_URL;
const WEATHER_API_KEY = process.env.REACT_APP_WEATHER_API_KEY;

// Function to get current weather
export const getCurrentWeather = async (cityName) => {
  try {
    // Build the full URL for the request
    const apiUrl = `${WEATHER_BASE_URL}/current.json?q=${cityName}&key=${WEATHER_API_KEY}`;
    // Perform a GET request to the weather API
    const response = await axios.get(apiUrl);
    // Return the response data
    return response.data;
  } catch (error) {
    console.error(error);
    throw error;
  }
};
