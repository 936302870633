import React from "react";
import styles from "./Loading.module.css";
import { SwishSpinner } from "react-spinners-kit";

const Loading = () => {
  return (
    <div className={styles["loading-container"]}>
      <SwishSpinner size={50} />
    </div>
  );
};

export default Loading;
