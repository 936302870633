import React from "react";
import { AiOutlineClose } from "react-icons/ai";
import styles from "./WeatherPanel.module.css";

const WeatherPanel = ({ setShowWeatherPanel, weather }) => {
    return (
        <div className={styles["weather-panel-container"]}>
            <AiOutlineClose
                className={styles["close-button"]}
                onClick={() => {
                    setShowWeatherPanel(false);
                }}
            />
            <h2 className={styles["weather-title"]}>Meteo</h2>
            <img
                src={weather?.current?.condition?.icon}
                alt='weather icon'
                className={styles["weather-icon"]}
            />
            <div className={styles["weather-info-container"]}>
                <div className={styles["column"]}>
                    <p>Percepita: <span>{weather.current.feelslike_c} °C</span></p>
                    <p>Umidità: <span>{weather.current.humidity} %</span></p>
                    <p>Pressione: <span>{weather.current.pressure_mb} mb</span></p>
                </div>
                <div className={styles["column"]}>
                    <p>Vento: <span>{weather.current.wind_kph} km/h</span></p>
                    <p>Direzione vento: <span>{weather.current.wind_dir}</span></p>
                    <p>Precipitazioni: <span>{weather.current.precip_mm} mm</span></p>
                </div>
            </div>
        </div>
    );
};

export default WeatherPanel;
