import React, { useState, useEffect } from "react";
import styles from "./SiloSvg.module.css";

const SiloSvg = ({ code, minStock, data, max_values, offset_values, onSiloSelected, setIsExpanded }) => {
    const [svgData, setSvgData] = useState(null);

    const width = 200;
    const height = 400;

    useEffect(() => {
        if (data !== null && data !== undefined) {
            const values = data.values;
            const sensorsValidity = data?.sensorsValidity;

            // Trova la scala per adattare i dati al grafico
            const scaleX = width / 40;
            const scaleY = height / Math.max(...max_values);

            const scaledOffsets = offset_values.map((point, index) => ({
                x: ((index + 1) * 10 - 10) * scaleX,
                y: point * scaleY,
            }));

            let scaledMax = max_values.map((point, index) => ({
                x: ((index + 1) * 10 - 10) * scaleX,
                y: point * scaleY,
            }));

            const scaledValues = values.map((point, index) => {
                if (sensorsValidity[index] === 0) {
                    // Se sensorsValidity[index] è 0, allora imposta scaledValues[index] a scaledMax[index]
                    return scaledMax[index];
                } else {
                    // Altrimenti, calcola scaledValues[index] normalmente
                    return {
                        x: ((index + 1) * 10 - 10) * scaleX,
                        y: Math.min((point + offset_values[index]) * scaleY, scaledMax[index].y),
                    };
                }
            });

            const indicesToKeep = [0, 2, 4];
            scaledMax = scaledMax.filter((_, index) => indicesToKeep.includes(index));

            // Crea un array di punti per il poligono riempito
            const fillPoints = [
                ...scaledValues,
                ...scaledMax.slice().reverse(), // Aggiungi i punti dei massimi in ordine inverso
            ];

            setSvgData({
                // Set data stock at min 0%
                areaDifferencePercentage: data.stock < 0 ? 0 : data.stock,
                fillPoints: fillPoints,
                scaledMax: scaledMax,
                scaledValues: scaledValues,
                scaledOffsets: scaledOffsets,
                sensorsValidity: sensorsValidity
            });
        }
    }, [data]);

    return (
        <svg width={width} height={height} style={{ overflow: 'visible' }}>

            {/* Offset line */}
            <polyline
                points={svgData?.scaledOffsets
                    .map((point) => `${point.x},${point.y}`)
                    .join(" ")}
                fill='none'
                stroke='white'
                strokeWidth='2'
            />

            {/* Red X invalid sens */}
            {svgData?.scaledOffsets.map((point, index) => (
                svgData?.sensorsValidity[index] === 0 && (
                    <line
                        key={index}
                        x1={point.x - 5}
                        y1={point.y - 5}
                        x2={point.x + 5}
                        y2={point.y + 5}
                        stroke='red'
                        strokeWidth='2'
                    />
                )
            ))}
            {svgData?.scaledOffsets.map((point, index) => (
                svgData?.sensorsValidity[index] === 0 && (
                    <line
                        key={index}
                        x1={point.x + 5}
                        y1={point.y - 5}
                        x2={point.x - 5}
                        y2={point.y + 5}
                        stroke='red'
                        strokeWidth='2'
                    />
                )
            ))}

            {/* Values line */}
            <polyline
                points={svgData?.scaledValues
                    .map((point) => `${point.x},${point.y}`)
                    .join(" ")}
                fill='none'
                stroke={
                    svgData?.areaDifferencePercentage.toFixed(1) >= minStock
                        ? "var(--cat-yellow)"
                        : "var(--alert-icon)"
                }
                strokeWidth='2'
            />

            {/* Polygon filled */}
            <polygon
                onClick={() => {
                    onSiloSelected(code);
                    setIsExpanded(true);
                }}
                className={styles["svg-polygon"]}
                points={svgData?.fillPoints
                    .map((point) => `${point.x},${point.y}`)
                    .join(" ")}
                fill={
                    svgData?.areaDifferencePercentage.toFixed(1) >=
                        minStock
                        ? "var(--cat-yellow)"
                        : "var(--alert-icon)"
                }
            />

            {/* Max line */}
            <polyline
                points={svgData?.scaledMax
                    .map((point) => `${point.x},${point.y}`)
                    .join(" ")}
                fill='none'
                stroke='white'
                strokeWidth='2'
            />

            {/* Percentage centered text */}
            <text
                x={width / 2}
                y={height / 2}
                textAnchor='middle'
                alignmentBaseline='middle'
                fontSize='24'
                fill='white'
            >
                {svgData?.areaDifferencePercentage == null
                    ? "Disconnesso"
                    : svgData?.sensorsValidity.includes(0)
                        ? "Errore sensori"
                        : `${svgData?.areaDifferencePercentage.toFixed(1)}%`}
            </text>

        </svg>
    )
};

export default SiloSvg;
