import React, { useState, useRef } from "react";
import styles from "./EditForm.module.css";
import {
  GiDroplets,
  GiFallingRocks,
  GiZigzagHieroglyph,
  GiNightSleep,
} from "react-icons/gi";
import { PiFan, PiTimer, PiClock } from "react-icons/pi";
import { TbTemperature } from "react-icons/tb";
import { RiAlarmWarningLine, RiTruckLine } from "react-icons/ri";
import { patchSilo } from "../services/apiService";
import { useStateContext } from "../contexts/ContextProvider";
import { toast } from "react-toastify";

const EditForm = ({
  id,
  code,
  name,
  content,
  description,
  supplier,
  sleepMode,
  fan,
  vibration,
  stock,
  log,
  setEditMode,
}) => {
  const { createAxiosInstance, convertFromSeconds, convertToSeconds } = useStateContext();
  const [vibrationFrequencyError, setVibrationFrequencyError] = useState(null);
  const [vibrationDurationError, setVibrationDurationError] = useState(null);
  const [updateData, setUpdateData] = useState({
    code: code,
    name: name,
    content: content,
    description: description,
    supplier: supplier,
    minStock: stock.min,
    vibrationFrequencyHH: convertFromSeconds(vibration.frequencySeconds).hours,
    vibrationFrequencyMM: convertFromSeconds(vibration.frequencySeconds).minutes,
    vibrationFrequencySS: convertFromSeconds(vibration.frequencySeconds).seconds,
    vibrationDurationHH: convertFromSeconds(vibration.durationSeconds).hours,
    vibrationDurationMM: convertFromSeconds(vibration.durationSeconds).minutes,
    vibrationDurationSS: convertFromSeconds(vibration.durationSeconds).seconds,
    sleepModeMMStart: sleepMode.mmStart,
    sleepModeHHStart: sleepMode.hhStart,
    sleepModeDurationHH: convertFromSeconds(sleepMode.durationSeconds).hours,
    sleepModeDurationMM: convertFromSeconds(sleepMode.durationSeconds).minutes,
    sleepModeFan: fan.sleepMode,
    sleepModeVibration: vibration.sleepMode,
    heightDifference: vibration.heightDifference,
    logFreq: log.frequencySeconds,
    stock: stock
  });

  // Update properties based on input name
  const handleNumericChange = (event) => {
    const { value } = event.target;
    const numericValue = parseInt(value, 10)

    setUpdateData((prevState) => ({
      ...prevState,
      [event.target.name]: numericValue,
    }));
  };

  // Check input value after lost focus
  const handleLostFocus = (event) => {
    const { value, min, max } = event.target;
    const numericValue = parseInt(value);
    let res

    res = Math.max(Number(min), Math.min(Number(max), Number(numericValue)));

    setUpdateData((prevState) => ({
      ...prevState,
      [`${event.target.name}`]: res
    }));
  };

  // Update checkbox properties
  const handleCheckboxChange = (event) => {
    setUpdateData((prevState) => ({
      ...prevState,
      [`${event.target.name}`]: event.target.checked,
    }));
  };

  const handleTextChange = (event) => {
    setUpdateData((prevState) => ({
      ...prevState,
      [`${event.target.name}`]: event.target.value,
    }));
  }

  const toastId = useRef(null);

  // Handle form submit
  const handleSubmit = async (event) => {
    event.preventDefault();
    toastId.current = toast.loading("Attendi...");
    setVibrationFrequencyError(null)
    setVibrationDurationError(null)

    try {
      const api = await createAxiosInstance();
      const silo = {
        ...updateData,
        vibrationFrequencySeconds: convertToSeconds(updateData.vibrationFrequencyHH, updateData.vibrationFrequencyMM, updateData.vibrationFrequencySS),
        vibrationDurationSeconds: convertToSeconds(updateData.vibrationDurationHH, updateData.vibrationDurationMM, updateData.vibrationDurationSS),
        sleepModeDurationSeconds: convertToSeconds(updateData.sleepModeDurationHH, updateData.sleepModeDurationMM, 0),
      }

      if (silo.vibrationFrequencySeconds < 3600) {
        setVibrationFrequencyError("La frequenza deve essere minimo 1h");
        throw Error("Vibration Frequency error")
      }

      if (silo.vibrationDurationSeconds > 1800 || silo.vibrationDurationSeconds < 10) {
        setVibrationDurationError("La vibrazione deve durare minimo 10s e massimo 30min")
        throw Error("Vibration Duration error")
      }

      await patchSilo(api, id, silo);
      toast.update(toastId.current, {
        render: `${code} Aggiornamento inserito ✏️`,
        type: toast.TYPE.SUCCESS,
        isLoading: false,
        autoClose: 5000,
        closeButton: true,
        draggable: true,
      });
      setEditMode(false)
    } catch (error) {
      console.log("Patch error:", error);
      toast.update(toastId.current, {
        render: `Aggiornamento del silo ${code} non riuscito 😩`,
        type: toast.TYPE.ERROR,
        isLoading: false,
        autoClose: 5000,
        closeButton: true,
        draggable: true,
      });
    }
  };

  return (
    <form onSubmit={handleSubmit} className={styles["edit-container"]}>
      <h2>Modifica parametri</h2>
      <div className={styles["scrollable-container"]}>
        <div className={styles["edit-property-container"]}>
          <>Name:</>
          <input
            type='text'
            name='name'
            value={updateData?.name}
            onChange={handleTextChange}
          />
        </div>
        <div className={styles["edit-property-container"]}>
          <>Prodotto:</>
          <input
            type='text'
            name='content'
            value={updateData?.content}
            onChange={handleTextChange}
          />
        </div>
        <div className={styles["edit-property-container"]}>
          <>Desc:</>
          <input
            type='text'
            name='description'
            value={updateData?.description}
            onChange={handleTextChange}
          />
        </div>
        <div className={styles["edit-property-container"]}>
          <RiTruckLine className={styles["edit-property-icon"]} /> Fornitore:{" "}
          <input
            type='text'
            name='supplier'
            value={updateData?.supplier}
            onChange={handleTextChange}
          />
        </div>
        <div className={styles["edit-property-container"]}>
          <div className={styles["edit-property-label"]}>
            <RiAlarmWarningLine className={styles["edit-property-icon"]} />{" "}
            allarme:{" "}
          </div>
          <div className={styles["edit-property-value"]}>
            <input
              className={styles["number-input"]}
              type='number'
              name='minStock'
              min={0}
              max={99}
              value={updateData?.minStock}
              onChange={handleNumericChange}
              onBlur={handleLostFocus}
              required
            />
            <div>%</div>
          </div>
        </div>

        {/* Autolevel section */}
        <div className={styles["edit-property-section-container"]}>
          <GiZigzagHieroglyph className={styles["edit-property-icon"]} />{" "}
          Autolivellamento
        </div>
        <div className={styles["edit-property-container"]}>
          <div className={styles["edit-property-label"]}>
            <GiFallingRocks className={styles["edit-property-icon"]} />{" "}
            dislivello:{" "}
          </div>
          <div className={styles["edit-property-value"]}>
            <input
              className={styles["number-input"]}
              type='number'
              name='heightDifference'
              min={30}
              max={250}
              value={updateData?.heightDifference}
              onChange={handleNumericChange}
              onBlur={handleLostFocus}
              required
            />
            cm
          </div>
        </div>
        <div className={styles["edit-property-container"]}>
          <div className={styles["edit-property-label"]}>
            <PiClock className={styles["edit-property-icon"]} /> frequenza:{" "}
          </div>
          <div className={styles["edit-property-value"]}>
            <input
              className={styles["number-input"]}
              type='number'
              name='vibrationFrequencyHH'
              min={0}
              max={23}
              value={updateData?.vibrationFrequencyHH}
              onChange={handleNumericChange}
              onBlur={handleLostFocus}
              required
            />
            h
            <input
              className={styles["number-input"]}
              type='number'
              name='vibrationFrequencyMM'
              min={0}
              max={59}
              value={updateData?.vibrationFrequencyMM}
              onChange={handleNumericChange}
              onBlur={handleLostFocus}
              required
            />
            m
            <input
              className={styles["number-input"]}
              type='number'
              name='vibrationFrequencySS'
              min={0}
              max={59}
              value={updateData?.vibrationFrequencySS}
              onChange={handleNumericChange}
              onBlur={handleLostFocus}
              required
            />
            s
          </div>
        </div>
        {vibrationFrequencyError && <div className={styles["edit-property-value"]}>
          <p className={styles["edit-property-error"]}>{vibrationFrequencyError}</p>
        </div>}
        <div className={styles["edit-property-container"]}>
          <div className={styles["edit-property-label"]}>
            <PiTimer className={styles["edit-property-icon"]} /> durata:{" "}
          </div>
          <div className={styles["edit-property-value"]}>
            <input
              className={styles["number-input"]}
              type='number'
              name='vibrationDurationHH'
              min={0}
              max={23}
              value={updateData?.vibrationDurationHH}
              onChange={handleNumericChange}
              onBlur={handleLostFocus}
              required
            />
            h
            <input
              className={styles["number-input"]}
              type='number'
              name='vibrationDurationMM'
              min={0}
              max={59}
              value={updateData?.vibrationDurationMM}
              onChange={handleNumericChange}
              onBlur={handleLostFocus}
              required
            />
            m
            <input
              className={styles["number-input"]}
              type='number'
              name='vibrationDurationSS'
              min={0}
              max={59}
              value={updateData?.vibrationDurationSS}
              onChange={handleNumericChange}
              onBlur={handleLostFocus}
              required
            />
            s
          </div>
        </div>
        {vibrationDurationError && <div className={styles["edit-property-value"]}>
          <p className={styles["edit-property-error"]}>{vibrationDurationError}</p>
        </div>}
        {/* Sleep mode section */}
        <div className={styles["edit-property-section-container"]}>
          <GiNightSleep className={styles["edit-property-icon"]} /> Sleep mode
        </div>
        <div className={styles["edit-property-container-checkbox"]}>
          <div className={styles["edit-property-label"]}>
            Ventola:{" "}
          </div>
          <div className={styles["edit-property-value-checkbox"]}>
            <input
              className={styles["checkbox-input"]}
              type='checkbox'
              name='sleepModeFan'
              checked={updateData?.sleepModeFan}
              onChange={handleCheckboxChange}
            />
          </div>
          <div className={styles["edit-property-label"]}>
            Vibrazione:{" "}
          </div>
          <div className={styles["edit-property-value-checkbox"]}>
            <input
              className={styles["checkbox-input"]}
              type='checkbox'
              name='sleepModeVibration'
              checked={updateData?.sleepModeVibration}
              onChange={handleCheckboxChange}
            />
          </div>
        </div>
        <div className={styles["edit-property-container"]}>
          <div className={styles["edit-property-label"]}>
            <PiClock className={styles["edit-property-icon"]} /> inizio:{" "}
          </div>
          <div className={styles["edit-property-value"]}>
            <input
              className={styles["time-input"]}
              type='number'
              name='sleepModeHHStart'
              min={0}
              max={23}
              value={updateData?.sleepModeHHStart}
              onChange={handleNumericChange}
              onBlur={handleLostFocus}
              required
            />
            h
            <input
              className={styles["time-input"]}
              type='number'
              name='sleepModeMMStart'
              min={0}
              max={59}
              value={updateData?.sleepModeMMStart}
              onChange={handleNumericChange}
              onBlur={handleLostFocus}
              required
            />
            m
          </div>
        </div>
        <div className={styles["edit-property-container"]}>
          <div className={styles["edit-property-label"]}>
            <PiTimer className={styles["edit-property-icon"]} /> durata:{" "}
          </div>
          <div className={styles["edit-property-value"]}>
            <input
              className={styles["number-input"]}
              type='number'
              name='sleepModeDurationHH'
              min={0}
              max={23}
              value={updateData?.sleepModeDurationHH}
              onChange={handleNumericChange}
              onBlur={handleLostFocus}
              required
            />
            h
            <input
              className={styles["time-input"]}
              type='number'
              name='sleepModeDurationMM'
              min={0}
              max={59}
              value={updateData?.sleepModeDurationMM}
              onChange={handleNumericChange}
              onBlur={handleLostFocus}
              required
            />
            m
          </div>
        </div>
        <button type='submit'>Save</button>
      </div>
    </form>
  );
};

export default EditForm;
