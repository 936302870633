// This file contains all the api requests of the application

// Basic URL from env
const BASE_URL = process.env.REACT_APP_API_BASE_URL;

// Get user company
export const GetUserCompany = async (api, username, password) => {
  try {
    // Make request
    const { data: response } = await api.post(`${BASE_URL}/users/login`, {
      username: username,
      password: password,
    });
    //return response
    return response;
  } catch (error) {
    console.log(error);
    throw error;
  }
};

// Get configuration
export const GetConfiguration = async (api) => {
  try {
    // Make request
    const { data: response } = await api.get(`${BASE_URL}/configurations`);
    //return response
    return response;
  } catch (error) {
    console.log(error);
    throw error;
  }
};

// Get all silos
export const getSilos = async (api) => {
  try {
    // Make request
    const { data: response } = await api.get(`${BASE_URL}/silos/`);
    //return response
    return response;
  } catch (error) {
    console.log(error);
    throw error;
  }
};

// Get specific silo
export const getSilo = async (api, id) => {
  try {
    const { data: response } = await api.get(`${BASE_URL}/silos/${id}`);

    //return response
    return response;
  } catch (error) {
    console.log(error);
    throw error;
  }
};

// Update specific silo
export const patchSilo = async (api, id, updateData) => {
  try {
    const response = await api.patch(`${BASE_URL}/silos/${id}`, {
      silo: {
        name: updateData.name,
        content: updateData.content,
        description: updateData.description,
        supplier: updateData.supplier,       
        stock: {
          min: parseInt(updateData.minStock),
          sensor: false,
          sensorNO: 0,
          outOfStock: updateData.stock?.outOfStock,
          predictionError: updateData.stock?.predictionError
        },
        sleepMode: {
          mmStart: parseInt(updateData.sleepModeMMStart),
          hhStart: parseInt(updateData.sleepModeHHStart),
          durationSeconds: parseInt(updateData.sleepModeDurationSeconds),
        },
        fan: {
          sleepMode: updateData.sleepModeFan,
        },
        vibration: {
          durationSeconds: parseInt(updateData.vibrationDurationSeconds),
          frequencySeconds: parseInt(updateData.vibrationFrequencySeconds),
          heightDifference: parseInt(updateData.heightDifference),
          sleepMode: updateData.sleepModeVibration,
        },
      },
    });

    return response.data;
  } catch (error) {
    console.log(error);
    throw error;
  }
};

// Get silo anag data
export const getSiloData = async (api, id) => {
  try {
    const { data: response } = await api.get(`${BASE_URL}/silos/${id}/data`);
    //return response
    return response;
  } catch (error) {
    console.log(error);
    throw error;
  }
};

// Get silo history for multiline chart
export const getSiloHistory = async (api, id) => {
  try {
    const { data: response } = await api.get(
      `${BASE_URL}/silos/${id}/data/history`
    );

    //return response
    return response;
  } catch (error) {
    console.log(error);
    throw error;
  }
};

// Get silo stock history for multiline chart
export const getSiloStockHistory = async (api, id) => {
  try {
    const { data: response } = await api.get(
      `${BASE_URL}/silos/${id}/data/stockHistory`
    );

    //return response
    return response;
  } catch (error) {
    console.log(error);
    throw error;
  }
};

// Get silo stock history for multiline chart
export const getUpdates = async (api, id) => {
  try {
    const { data: response } = await api.get(
      `${BASE_URL}/silos/${id}/updates`
    );

    //return response
    return response;
  } catch (error) {
    console.log(error);
    throw error;
  }
};
