import React, { useState, Fragment, useEffect } from "react";
import styles from "./MultilineChart.module.css";
import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  Tooltip,
  ResponsiveContainer,
} from "recharts";
import { useStateContext } from "../contexts/ContextProvider";

const MultilineChart = ({ data }) => {
  const { formatTimestamp } = useStateContext();
  const [chartData, setChartData] = useState(data);

  useEffect(() => {
    setChartData(data);
  }, [data]);

  const customDot = (props) => {
    const { cx, cy, stroke, payload, value } = props;

    return (
      <circle key={`dot-${cx}-${cy}`} cx={cx} cy={cy} r={2} fill={stroke} />
    );
  };

  const CustomTooltip = ({ active, payload, label }) => {
    if (active && payload && payload.length) {
      return (
        <div className={styles["custom-tooltip"]}>
          <p className={styles["timestamp"]}>{formatTimestamp(label, '', 'dd/MM/yy HH:mm:ss')}</p>
          {payload.map((entry, index) => (
            <p key={`value-${index}`} style={{ color: entry.color }}>
              {`${entry.name}: ${entry.value}`}
            </p>
          ))}
        </div>
      );
    }
    return null;
  };

  return (
    <div className={styles["multiline-chart-container"]}>
      <ResponsiveContainer width={"99%"} height={140}>
        <LineChart data={chartData}>
          <XAxis
            dataKey='timestamp'
            name='Timestamp'
            type='category'
            allowDuplicatedCategory={false}
            tick={false}
          />
          <YAxis hide />
          <Tooltip
            contentStyle={{ backgroundColor: "#000000bf", borderWidth: "0px" }}
            content={<CustomTooltip />}
          />
          {chartData?.map((s, index) => (
            <Fragment key={`fragment-${s.name}-${index}`}>
              <YAxis
                key={`y-axis-${s.name}-${index}`}
                yAxisId={s.name}
                scale='auto'
                hide
              />
              <Line
                dataKey='value'
                yAxisId={s.name}
                data={s.data}
                name={s.name}
                key={`line-${s.name}-${index}`}
                stroke={s.color}
                isAnimationActive={false}
                type={s.lineType ? s.lineType : "basis"}
                dot={customDot}
              />
            </Fragment>
          ))}
        </LineChart>
      </ResponsiveContainer>
    </div>
  );
};

export default MultilineChart;
